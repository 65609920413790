<template>
  <div>
    <b-button
      v-if="!mainRoom && isStaff"
      v-b-modal.modal-create-main-room
      class="create-button mr-1 mb-5"
      variant="outline-primary"
    >
      {{ $t("challenges.create") }}
    </b-button>
    <component 
      :is="$route.name === 'Resumen' ? 'div' : 'b-card'" 
      class="room-card pb-2"
    >
      <div v-if="mainRoom">
        <component
          :is="currentComponent"
          :room="mainRoom"
          :initial-configuration="initialConfiguration"
          @join-meeting="handleJoinMeeting"
          @leave-meeting="handleLeaveMeeting"
          @edited="handleEdited"
        />
        <b-card
          v-if="mainRoom.Meetings && mainRoom.Meetings.length"
          title="Agenda"
          style="margin: 0 1rem;"
        >
          <events-agenda 
            :forced-events="mainRoom.Meetings" 
            :centered="false" 
          />
        </b-card>
      </div>
      <div
        v-else
        class="horizontal-placeholder placeholder-container d-flex flex-column"
      >
        <img :src="roomsPlaceholder" />
        <p class="text-primary">
          {{ $t("available.message-auditorium") }}
        </p>
      </div>
    </component>

    <b-modal
      id="modal-create-main-room"
      :title="$t('auditorium.create')"
      :ok-title="$t('form.actions.save')"
      :cancel-title="$t('form-create-item.cancel')"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      ok-only
      @ok="handleCreateItem"
    >
      <b-form>
        <b-form-group>
          <label for="name">{{ $t("rooms.name") }}:</label>
          <b-form-input id="name" v-model="formInput.name" type="text" />
        </b-form-group>
        <b-form-group>
          <label class="mt-2" for="description"
            >{{ $t("form-create-item.description") }}:</label
          >
          <quill-editor v-model="formInput.description" />
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group>
              <label for="typeEvent">{{ $t("rooms.modality") }}:</label>
              <v-select
                id="typeEvent"
                v-model="selectedOption"
                :clearable="false"
                :options="typeEventsOptions"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="type">{{ $t("people.type") }}:</label>
              <v-select
                id="type"
                v-model="selectedType"
                :clearable="false"
                :options="typeOptions"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group>
          <label for="app">App:</label>
          <v-select
            id="app"
            v-model="selectedApp"
            :clearable="false"
            :options="appsOptions"
            label="name"
          />
        </b-form-group>

        <p class="mt-3">{{ $t("form-create-item.image") }}:</p>
        <div class="border rounded p-2">
          <p class="mb-50">
            {{ $t("form-create-item.image-select") }}
          </p>
          <b-media
            no-body
            vertical-align="center"
            class="d-flex align-items-center flex-column flex-md-row"
          >
            <b-media-aside>
              <b-img
                ref="refPreviewEl"
                :src="imageSrc"
                height="110"
                width="170"
                class="rounded mr-2 mb-1 mb-md-0"
                style="background: #c0c0c0"
              />
            </b-media-aside>
            <b-media-body>
              <div class="d-inline-block d-flex align-items-center pl-2">
                <b-form-file
                  v-model="itemImage"
                  class="pointer"
                  :accept="FileTypes"
                  :placeholder="$t('form-create-item.image-placeholder')"
                  :browse-text="$t('form-create-item.image-button')"
                />
              </div>
            </b-media-body>
          </b-media>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import EventsAgenda from "@/views/apps/events/components/EventsAgenda";
import RoomsPlaceholder from "@/assets/images/placeholders/light/rooms.svg";
import { RoomProvider } from "@copernicsw/community-common";
import RoomWelcomeMeeting from "@/views/apps/rooms/components/room-details/RoomWelcomeMeeting.vue";
import RoomPreparationMeeting from "@/views/apps/rooms/components/room-details/RoomPreparationMeeting.vue";
import RoomStartMeeting from "@/views/apps/rooms/components/room-details/RoomStartMeeting.vue";
import RoomClosedMeeting from "@/views/apps/rooms/components/room-details/RoomClosedMeeting.vue";
import AuditoriumWelcome from "@/views/apps/rooms/components/room-details/AuditoriumWelcome.vue";
import BlueJeansMeeting from "@/views/apps/rooms/components/room-details/BlueJeansMeeting.vue";
import BigBlueButton from "@/views/apps/rooms/components/room-details/BigBlueButton.vue";
import FileTypes from "@/@core/constants/FileTypes";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import {
  ENABLED_APPS_GETTERS,
} from "@/store/enabled-apps/enabled-apps-store-constants";
const MeetingSteps = Object.freeze({
  auditorium: "meeting-step-auditorium",
  welcome: "meeting-step-welcome",
  preparation: "meeting-step-preparation",
  start: "meeting-step-start",
  closed: "meeting-step-close",
});

export default {
  name: "AuditoriumDetails",
  components: {
    quillEditor,
    vSelect,
    RoomsPlaceholder,
    EventsAgenda,
    RoomWelcomeMeeting,
    RoomPreparationMeeting,
    RoomStartMeeting,
    RoomClosedMeeting,
  },
  props: {
    skipWelcome: Boolean,
    skipWelcomePreparation: Boolean,
  },
  data() {
    return {
      formInput: {
        name: null,
        description: "",
        modality: null,
        app: null,
        type: null,
      },
      currentStep: MeetingSteps.welcome,
      initialConfiguration: {
        isMicOn: true,
        isVideoOn: true,
      },

      itemImage: null,
      imageSrc: null,
      selectedOption: this.$t("rooms.online"),
      selectedApp: "Conferencing",
      selectedType: "Meeting",
      FileTypes,
    };
  },
  computed: {
    appsOptions() {
      return this.appRoom;
    },
    typeEventsOptions() {
      return [
        this.$t("rooms.online"),
        this.$t("rooms.on-site"),
        this.$t("rooms.hybrid"),
      ];
    },
    typeOptions() {
      return ["Meeting", "Webinar"];
    },
    isStaff() {
      return this.collective.isStaff;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    roomsPlaceholder() {
      return RoomsPlaceholder;
    },
    itemsData() {
      return this.$store.getters.rooms;
    },
    mainRoom() {
      let room = this.itemsData.unpaginated?.find(
        (room) => room.isMain === true
      );
      if (room) {
        this.roomName = room.name;
      }

      return this.itemsData.unpaginated?.find((room) => room.isMain === true);
    },
    currentComponent() {
      console.log('Auditorium currentComponent:', this.currentStep);
      switch (this.currentStep) {
        case MeetingSteps.auditorium:
          return AuditoriumWelcome;
        case MeetingSteps.welcome:
          return RoomWelcomeMeeting;
        case MeetingSteps.preparation:
          return this.roomApp;
        case MeetingSteps.start:
          return this.roomApp;
        case MeetingSteps.closed:
          return RoomClosedMeeting;
        default:
          // should never happen.
          return null;
      }
    },
    roomApp() {
      switch (this.mainRoom.app) {
        case RoomProvider.bluejeans:
          return BlueJeansMeeting;
        case RoomProvider.bbb:
        case RoomProvider.bbbUsingCommunityKey:
          return BigBlueButton;
        default:
          return RoomStartMeeting;
      }
    },
    conferenceApp() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].auditorium;
    },
  },
  created() {
    this.updateBreadcrumbs();
    this.fetchData();
    if (this.$route.name === 'Resumen') {
      if (this.conferenceApp?.customization?.displayOptions === 0) {
        this.currentStep = MeetingSteps.auditorium;
      } else if (this.conferenceApp?.customization?.displayOptions === 1) {
        this.currentStep = MeetingSteps.welcome;
      } else if (this.conferenceApp?.customization?.displayOptions === 2) {
        this.currentStep = MeetingSteps.preparation;
      } else if (this.conferenceApp?.customization?.displayOptions === 3) {
        this.currentStep = MeetingSteps.start;
      } else {
        this.currentStep = MeetingSteps.auditorium;
      }
    }
  },
  mounted() {
    if (this.skipWelcome) {
      this.currentStep = MeetingSteps.preparation;
    }
    if (this.skipWelcomePreparation) {
      this.currentStep = MeetingSteps.start;
    }
  },
  methods: {
    updateBreadcrumbs() {
      this.$store.commit("app/SET_BREADCRUMBS", null);
    },
    resetInputs() {
      this.formInput = {};
      this.itemImage = null;
      this.imageSrc = null;
    },
    async handleCreateItem() {
      if (!this.formInput.name) {
        this.formError = false;
        return;
      }
      try {
        this.modality();
        this.appss();
        this.type();
        await this.$store.dispatch("createItem", {
          item: {
            itemType: "rooms",
            forceAPICall: true,
            requestConfig: {
              name: this.formInput.name,
              description: this.formInput.description,
              modality: this.formInput.modality,
              app: this.formInput.app != "" ? this.formInput.app : null,
              type: this.formInput.type,
              isMain: 1,
            },
          },
          file: this.itemImage,
        }); 
        this.resetInputs();
        this.productInput = {};
        this.formError = null;
        this.itemImage = null;
        (this.selectedOption = this.$t("rooms.online")),
          (this.selectedApp = "Conferencing");
        this.selectedType = "Meeting";
      } catch (e) {
        console.log(e);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("error-message.general-error"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
      modality() {
      switch (this.selectedOption) {
        case this.typeEventsOptions[1]:
          this.formInput.modality = "physical";
          return;
        case this.typeEventsOptions[2]:
          this.formInput.modality = "hybrid";
          return;
        case this.typeEventsOptions[0]:
          this.formInput.modality = "online";
      }
    },
     appss() {
      switch (this.selectedApp) {
        case "Conferencing":
          this.formInput.app = "jitsi";
          return;
        case "Zoom.us":
          this.formInput.app = "zoom";
          return;
        case "Nectios Classroom":
          this.formInput.app = "classroom";
          return;
        case "Bluejeans":
          this.formInput.app = "bluejeans";
          return;
        case "Jisti":
          this.formInput.app = "jitsi";
      }
    },
    type() {
      switch (this.selectedType) {
        case this.typeOptions[0]:
          this.formInput.type = "meeting";
          return;
        case this.typeOptions[1]:
          this.formInput.type = "webinar";
      }
    },
    async fetchData() {
      await this.$store.dispatch("getItems", {
        itemType: "rooms",
        page: this.lastLoadedPage,
        // Workaround to ensure that the main room is located
        // TODO: request second page if mainRoom is not found
        perPage: 1000,
        forceAPICall: true,
        requestConfig: {
          orderByDate: -1,
        },
      });
      this.isLoading = false;
    },
    handleJoinMeeting(config) {
      this.currentStep =
        this.currentStep === MeetingSteps.welcome
          ? MeetingSteps.preparation
          : MeetingSteps.start;
      if (config) {
        this.initialConfiguration = config;
      }
    },
    handleLeaveMeeting() {
      this.currentStep =
        this.$route.name !== 'Resumen'
          ? MeetingSteps.welcome
          : MeetingSteps.auditorium;
    },
    handleEdited() {
      setTimeout(() => {
        this.fetchData();
      }, 2000)
    },
  },
};
</script>
<style lang="scss">
@import "@/views/apps/rooms/scss/rooms.scss";
.placeholder-container {
  height: 450px;
}
.create-button {
  position: absolute;
  margin-top: -60px;
  right: 24px;
}
</style>
